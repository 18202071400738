<template>
  <vue-video-player
    class="vjs-custom-skin "
    :options="playerOptions"
    :playsinline="true"
  />
</template>

<script>
import { videoPlayer as VueVideoPlayer } from 'vue-video-player';

import 'video.js/dist/video-js.css';

export default {
  name: 'VideoPlayer',
  components: { VueVideoPlayer },
  props: {
    src: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: '',
    },
    thumbnailURL: {
      type: String,
      default: '',
    },
    autoplay: Boolean,
  },
  computed: {
    playerOptions() {
      const playerOptions = {
        autoplay: this.autoplay,
        language: this.$store.state.locale.currentLocale,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: this.type,
          src: this.src,
        }],
        poster: this.thumbnailURL,
      };
      //console.log('player options:', playerOptions);
      return playerOptions;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include'; // Bootstrap includes

/**
 * Copied from vue-video-player example
 * @see https://github.com/surmon-china/vue-video-player/blob/master/src/custom-theme.css
 */
.vjs-custom-skin::v-deep {
  & > .video-js {
    position: relative;
    // max-height: 500px;
    width: 100%;
    height: auto;
    & > .vjs-tech {
      position: relative;
      // max-height: 500px;
    }
  }

  & > .video-js .vjs-menu-button-inline.vjs-slider-active,
  & > .video-js .vjs-menu-button-inline:focus,
  & > .video-js .vjs-menu-button-inline:hover,
  .video-js.vjs-no-flex .vjs-menu-button-inline {
    width: 10em;
  }

  & > .video-js .vjs-controls-disabled .vjs-big-play-button {
    display: none!important
  }

  & > .video-js .vjs-control {
    width: 3em;
  }

  & > .video-js .vjs-control.vjs-live-control{
    width: auto;
    padding-left: .5em;
    letter-spacing: .1em;
  }

  & > .video-js .vjs-control {
    @include media-breakpoint-down(lg) {
      position: relative;
      height: 100%;
      width: 3em;
      flex: auto;
    }
  }
  & > .video-js .vjs-menu-button-inline:before {
    width: 1.5em;
  }

  .vjs-menu-button-inline .vjs-menu {
    left: 3em;
  }

  &.vjs-paused.vjs-has-started > .video-js .vjs-big-play-button,
  .video-js.vjs-ended .vjs-big-play-button,
  .video-js.vjs-paused .vjs-big-play-button {
    display: block;
  }

  & > .video-js .vjs-load-progress div,
  .vjs-seeking .vjs-big-play-button,
  .vjs-waiting .vjs-big-play-button {
    display: none!important
  }

  & > .video-js .vjs-mouse-display:after,
  & > .video-js .vjs-play-progress:after {
    padding: 0 .4em .3em;
  }

  .video-js.vjs-ended .vjs-loading-spinner {
    display: none;
  }

  .video-js.vjs-ended .vjs-big-play-button {
    display: block !important;
  }

  .video-js.vjs-ended .vjs-big-play-button,
  .video-js.vjs-paused .vjs-big-play-button,
  &.vjs-paused.vjs-has-started > .video-js .vjs-big-play-button {
    display: block
  }

  & > .video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
    margin-left: -1.5em;
    margin-top: -1em;
  }

  & > .video-js .vjs-big-play-button {
    background-color: rgba(0,0,0,0.45);
    font-size: 3.5em;
    height: 2em !important;
    line-height: 2em !important;
    margin-top: -1em !important;
  }

  .video-js:hover .vjs-big-play-button,
  & > .video-js .vjs-big-play-button:focus,
  & > .video-js .vjs-big-play-button:active {
    background-color: rgba($primary,0.9);
  }

  & > .video-js .vjs-loading-spinner {
    border-color: rgba($primary,0.8);
  }

  & > .video-js .vjs-control-bar2 {
    background-color: #000000;
  }

  & > .video-js .vjs-control-bar {
    color: #ffffff;
    font-size: 14px
  }

  & > .video-js .vjs-play-progress,
  & > .video-js  .vjs-volume-level {
    background-color: $primary;
  }

  & > .video-js .vjs-play-progress:before {
    top: -0.3em;
  }

  & > .video-js .vjs-progress-control:hover .vjs-progress-holder {
    font-size: 1.3em;
  }

  .vjs-menu-button-popup.vjs-volume-menu-button-vertical .vjs-menu {
    left: 0em;
  }

  & > .video-js .vjs-menu li {
    padding: 0;
    line-height: 2em;
    font-size: 1.1em;
  }

  & > .video-js .vjs-time-tooltip,
  & > .video-js .vjs-mouse-display:after,
  & > .video-js .vjs-play-progress:after {
    border-radius: 0;
    font-size: 1em;
    padding: 0;
    width: 3em;
    height: 1.5em;
    line-height: 1.5em;
    top: -3em;
  }

  & > .video-js .vjs-menu-button-popup .vjs-menu {
    width: 5em;
    left: -1em;
  }

  & > .video-js .vjs-menu-button-popup.vjs-volume-menu-button-vertical .vjs-menu {
    left: 0;
  }

  /* Sort order */
  & > .video-js .vjs-control-bar .vjs-play-control {
    order: 0;
  }

  & > .video-js .vjs-control-bar .vjs-time-control {
    min-width: 1em;
    padding: 0;
    margin: 0 .1em;
    text-align: center;
    display: block;
    order: 1;
  }

  & > .video-js .vjs-control-bar .vjs-playback-rate .vjs-playback-rate-value{
    font-size: 1.2em;
    line-height: 2.4;
  }

  & > .video-js .vjs-progress-control.vjs-control {
    order: 2;
  }

  & > .video-js .vjs-control-bar .vjs-volume-menu-button {
    order: 3;
  }

  & > .video-js .vjs-control-bar .vjs-resolution-button {
    order: 4;
  }

  & > .video-js .vjs-control-bar .vjs-resolution-button .vjs-resolution-button-label {
    display: block;
    line-height: 3em;
  }

  & > .video-js .vjs-control-bar .vjs-playback-rate {
    order: 5;
  }

  & > .video-js .vjs-control-bar .vjs-fullscreen-control {
    order: 6;
  }
}
</style>
